.game {
  max-width: 500px;
  margin: 0 auto;
}

.body {
  display: flex;
}

@media screen and (max-width: 500px) {
  .body {
    display: block;
  }
}

.help {
  color: #ddd;
  margin: 5px;
  text-align: center;
  height: 0px;
  overflow-y: hidden;
  transition: height 1s ease;
}
.activity-container {
  color: #ddd;
  margin: 5px;
  height: 0px;
  overflow-y: hidden;
  transition: height 1s ease;
}
.help-show,
.activity-container-show {
  height: 55px;
  overflow-y: scroll;
}

.activity {
  height: 0px;
  overflow-y: hidden;
  transition: height 1s ease;
}
.activity-show {
  height: 18px;
}

.scores-container {
  color: #ddd;
  margin: 5px;
}
.score {
  display: flex;
  justify-content: space-between;
}
.container {
  display: flex;
  justify-content: center;
  text-align: center;
}

.left {
  text-align: center;
  width: 250px;
  height: 180px;
  border: thin solid #ddd;
}

.right {
  text-align: center;
  width: 250px;
  height: 180px;
  border: thin solid #ddd;
}

.star {
  display: inline-block;
  margin: 0 15px;
  color: #888;
}

.star:after {
  content: "\2605";
  font-size: 35px;
}

.number {
  background-color: #eee;
  border: thin solid #ddd;
  width: 35px;
  height: 35px;
  margin: 10px 15px;
  font-size: 25px;
}

.number:focus,
.number:active {
  outline: none;
  border: thin solid #ddd;
}

.status {
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
  margin-left: 3px;
}
.timer {
  color: #ddd;
}
.countdown {
  display: inline-block;
  width: 25px;
}
.games-won {
  color: #ddd;
}

.game-done .message {
  font-size: 200%;
  font-weight: bold;
  margin: 10px;
}

button {
  font-size: 1rem;
  margin: 0.5rem;
  width: 12rem;
  cursor: pointer;
}

.app-saved,
.app-update {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #eee;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  max-height: 0px;
  overflow-y: hidden;
  transition: max-height 1s ease;
}
.app-saved-show,
.app-update-show {
  max-height: 150px;
}
.app-saved button,
.app-update button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 0;
  text-decoration: none;
  background: #333;
  color: #ffffff;
  font-family: sans-serif;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 1rem 1rem;
}

.signin-image {
  width: 80px;
  margin-top: -17px;
  margin-bottom: -22px;
  margin-left: -5px;
  margin-right: -5px;
  overflow: hidden;
}
